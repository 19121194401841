<template>
  <div>
    <l-geo-json
      :geojson="data"
      :options="options"
      :options-style="styleOptions"
    ></l-geo-json>
    <v-snackbar color="error" v-model="showError" timeout="-1" app>
      Deze kaart is momenteel niet beschikbaar, probeer later opnieuw
    </v-snackbar>
    <v-snackbar light color="grey lighten-3" v-model="loading" timeout="-1" app>
      Bezig met laden van de kaart...
    </v-snackbar>
  </div>
</template>
<script>
import { LGeoJson } from "vue2-leaflet";
export default {
  components: { LGeoJson },
  props: {
    bounds: {
      required: true,
    },
    styleOptions: {
      type: Function,
      default: () => {},
    },
    pointToLayer: {
      type: Function,
      default: () => {},
    },
    onEachFeature: {
      type: Function,
      default: () => {},
    },
    filter: {
      type: Function,
      default: null,
    },
    wfsUrl: {
      type: String,
      required: true,
    },
    outputFormat: {
      type: String,
      required: true,
    },
    typeName: {
      type: String,
      required: true,
    },
    srsName: {
      type: String,
      required: true,
    },
    propertyName: {
      type: String,
    },
    sortBy: {
      type: String,
    },
    version: {
      type: String,
    },
    useProxy: {
      type: Boolean,
      default: true,
    },
    useBbox: {
      type: Boolean,
      default: true,
    },
    reverseBbox: {
      type: Boolean,
      default: false,
    },
    minLoadDataZoom: {
      type: Number,
      default: 14,
    },
    minRefetchDistance: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      data: null,
      showError: false,
      loading: true,
      lastFetchBounds: null,
    };
  },
  computed: {
    zoom() {
      return this.$store.state.zoom;
    },
    options() {
      return {
        onEachFeature: this.onEachFeature,
        pointToLayer: this.pointToLayer,
        filter: this.filter,
      };
    },
  },
  watch: {
    bounds(newBounds) {
      this.bbox = newBounds.toBBoxString();
      const distance =
        this.lastFetchBounds?.getCenter().distanceTo(newBounds?.getCenter()) ||
        null;
      if (
        (distance === null || distance >= this.minRefetchDistance) &&
        this.useBbox === true
      ) {
        this.lastFetchBounds = newBounds;
        this.fetchData();
      }
    },
  },
  methods: {
    async fetchData() {
      this.showError = false;

      if (this.zoom < this.minLoadDataZoom) return;

      try {
        const response = await fetch(this.dataUrl());
        const data = await response.json();
        this.data = data;
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
        this.showError = true;
      }
    },
    dataUrl() {
      let url = "";

      if (this.useProxy) {
        url += "https://proxy.piepkaart.nl/";
      }

      url += `${this.wfsUrl}?service=WFS&request=GetFeature&typename=${this.typeName}&outputFormat=${this.outputFormat}&srsName=${this.srsName}`;

      if (this.propertyName) {
        url += `&propertyName=${this.propertyName}`;
      }

      if (this.sortBy) {
        url += `&sortBy=${this.sortBy}`;
      }

      if (this.version) {
        url += `&version=${this.version}`;
      }

      if (this.useBbox) {
        if (this.reverseBbox) {
          url += `&bbox=${this.bbox.split(",").reverse().join(",")},${
            this.srsName
          }`;
        } else {
          url += `&bbox=${this.bbox},${this.srsName}`;
        }
      }

      return url;
    },
  },
  mounted() {
    if (this.useBbox === false) {
      this.fetchData();
    }
  },
};
</script>
