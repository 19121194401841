<template>
  <piepkaart :minLoadDataZoom="1">
    <template v-slot="{ bounds, minLoadDataZoom }">
      <piepkaart-wfs-layer
        :bounds="bounds"
        :minLoadDataZoom="minLoadDataZoom"
        wfs-url="https://geoservices.provinciegroningen.nl/server/services/Beleidsplannen/Omgevingsvisie/MapServer/WFSServer"
        type-name="Beleidsplannen_Omgevingsvisie:GeslotenStortplaatsen"
        output-format="GEOJSON"
        srs-name="EPSG:4326"
        :use-proxy="true"
        :use-bbox="false"
        :onEachFeature="onEachFeature"
        :styleOptions="styleOptions"
        :minRefetchDistance="10"
      ></piepkaart-wfs-layer>
    </template>
  </piepkaart>
</template>
<script>
import Piepkaart from "@/components/Piepkaart.vue";
import PiepkaartWfsLayer from "@/components/PiepkaartWfsLayer.vue";
import L from "leaflet";
export default {
  name: "Groningen",
  components: {
    Piepkaart,
    PiepkaartWfsLayer,
  },
  data() {
    return {
      maxBounds: L.latLngBounds([
        [53.09862169952043, 6.471403286524213],
        [53.38954491294036, 7.08273722618986],
      ]),
    };
  },
  methods: {
    styleOptions() {
      return {
        weight: 3,
        color: "#3B82F6",
        opacity: 1,
        fillColor: "#3B82F6",
        fillOpacity: 0.8,
      };
    },
    onEachFeature(feature, layer) {
      if (feature.properties) {
        layer.bindPopup(
          `
            <div>
              <div style="font-weight: 600">${feature.properties.NAAM}</div>
            </div>
            `,
          {
            closeButton: false,
            autoPan: false,
          }
        );
      }
    },
  },
  created() {
    this.$store.commit("center", this.maxBounds.getCenter());
    this.$store.commit("zoom", 10);
  },
};
</script>
